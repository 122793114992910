import React from 'react'
import Grid from '@mui/material//Grid'
import Typography from '@mui/material/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '@mui/material/Link'

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <Grid container direction='column' alignItems='center' spacing={6}>
        <Grid item>
          <StaticImage src='../images/nathanpic.jpeg' width={300} alt='nathan' />
        </Grid>
        <Grid item>
          <Typography align='center' sx={{ fontSize: '2rem' }}>Hi, I'm Nathan!</Typography>
        </Grid>
        <Grid item>
          <Typography paragraph={true}>
            I’m an engineer and entrepreneur based in Brooklyn, NY. I help build data-driven 
            products, teams, and companies through my work at at&nbsp;
              <Link
                href='https://www.endeavorlabs.co'
                target='_blank'
                rel='noopener'
                sx={{ color: 'text.secondary' }}
              >
                Endeavor Labs
              </Link>
            .
          </Typography>
          <Typography paragraph={true}>
            Most recently, I was Director of Engineering at Electric, a venture-backed startup 
            in the IT management space. I joined the company in 2018 as their first data hire, 
            and grew into a broader management role, eventually leading a team of 30 engineers, 
            data scientists, and analysts. 
          </Typography>
          <Typography paragraph={true}>
            Prior to Electric, I spent about eight years in the cleantech software space, 
            starting as an energy markets analyst at EnerNOC, then founding a predictive 
            analytics SaaS called GridReason, and later joining Lucid to extend the analytical 
            capabilities of BuildingOS, an energy management platform for commercial buildings.
          </Typography>
          <Typography paragraph={true}>
            What else? I tend to cycle through hobbies, although in recent years I’ve been an 
            active home chef, tennis player, builder of autonomous drones, and web3 enthusiast. 
            I also travel quite a bit, usually with my lovely wife&nbsp;
              <Link 
                href='https://www.stephanienewman.com'
                target='_blank'
                rel='noopener'
                sx={{ color: 'text.secondary' }}
              >
                Stephanie Newman
              </Link>
            .
          </Typography>
        </Grid>

      </Grid>
    </Layout>
  )
}

export default AboutPage
